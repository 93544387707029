import React from "react";
import { ModalParent } from "../elements/Modal";
import LoadingIcon from "../elements/LoadingIcon";
import useApiRequest from "../hooks/useApiRequest";

export default function HelpModal(props) {
  const { cookieData = false, isHelpOpen, setIsHelpOpen } = props;
  const { fetchDataOnDemand } = useApiRequest({ isCallOnDemand: true });
  // let cookieData = {
  //   legacyuser: false,
  //   userid: 75,
  //   useruuid: "0e063aef-802c-11ea-957b-16f36e376a09",
  //   firstname: "Jack",
  //   lastname: "McCormick",
  //   email: "jacktest@test.com",
  //   organizations: [
  //     {
  //       organizationid: 61,
  //       organizationuuid: "1a431661-802c-11ea-957b-16f36e376a09",
  //       name: "Test Success Inc",
  //       isfunder: false,
  //       organizationrole: null,
  //     },
  //     {
  //       organizationid: 62,
  //       organizationuuid: "b0e37364-8048-11ea-957b-16f36e376a09",
  //       name: "SC TEST PREP",
  //       isfunder: false,
  //       organizationrole: null,
  //     },
  //   ],
  //   roles: [{ roleid: 1, name: "GodMode" }],
  //   isinternal: false,
  //   passwordresetrequired: false,
  //   iat: 1597163870,
  //   exp: 1597192670,
  // };

  const initialState = {
    email: cookieData ? cookieData.email : "",
    comments: "",
  };
  //used for help modal inputs
  const [formState, setFormState] = React.useState(initialState);

  // used to disable help form submit button (needed because of spam capabilities)
  const [isDisabled, setIsDisabled] = React.useState(false);

  //used for submit check for home modal
  const [isSubmitted, setIsSubmitted] = React.useState(false);

  //used to storing loading state
  const [isLoading, setIsLoading] = React.useState(false);

  //used in help modal and gives error if true
  const [isError, setIsError] = React.useState(false);

  // used to check if the help modal submit is successful or not
  function checkSubmitState() {
    if (isSubmitted) {
      return "Request has been submitted succesfully";
    }
    if (isError) {
      return "There was an error processing your request, Please try again";
    }
  }

  //checks isLoading is true and can be used for anything that needs a loading symbol
  function checkLoading() {
    return isLoading ? <LoadingIcon /> : "";
  }

  //handles input changes for the Help text boxes
  const handleInputChange = (e) => {
    //assigning e.target to variables retaining the information for the instance
    let inputName = e.target.name;
    let inputValue = e.target.value;
    setFormState((prev) => ({ ...prev, [inputName]: inputValue }));
  };

  //handles the help modal submit, prevents redirect and creates a new task in asana and then returns either success or no success
  const handleSubmitEvent = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setIsDisabled(true);
      let body;
      if (cookieData) {
        body = {
          organizationid: cookieData.organizations[0].organizationid.toString(),
          email: formState.email,
          location: window.location.href,
          comments: formState.comments,
          userid: cookieData.userid,
        };
      } else {
        body = {
          email: formState.email,
          location: window.location.href,
          comments: formState.comments,
        };
      }
      let response = await fetchDataOnDemand({
        reqType: "addHelpRequest",
        body,
      });
      setIsLoading(false);
      setIsSubmitted(true);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      setIsDisabled(false);
      console.log(error);
    }
  };

  //gets rid of all entered values and text in help modal
  const handleClose = (e) => {
    setFormState(initialState);
    setIsHelpOpen(false);
    setIsSubmitted(false);
    setIsError(false);
    setIsDisabled(false);
    // document.body.style.overflow = "unset";
  };

  return (
    <ModalParent isOpen={isHelpOpen} closeFunction={handleClose}>
      <div className="mt-3 block text-3xl font-medium leading-5 text-gray-700">
        Help Request
      </div>

      <form onSubmit={(e) => handleSubmitEvent(e)}>
        <div className="mt-3">
          <label
            htmlFor="email"
            className="block text-sm font-medium leading-5 text-gray-700"
          >
            Email
          </label>
          <div className="mt-1 rounded-md shadow-sm w-3/4">
            <input
              id="email"
              name="email"
              type="email"
              required={true}
              className="block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              value={formState.email}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
        </div>

        <div className="mt-3">
          <label
            htmlFor="comments"
            className="block text-sm font-medium leading-5 text-gray-700"
          >
            Comments/Issues
          </label>
          <div className="mt-1 rounded-md shadow-sm">
            <textarea
              id="comments"
              name="comments"
              required={true}
              rows="5"
              className="block h-full w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              value={formState.comments}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
        </div>

        <div className="block text-sm font-medium leading-5 text-gray-700">
          {checkSubmitState()}
        </div>

        <div className="mt-6 flex items-center justify-end">
          <div className="flex items-center mr-2">
            <div className="mr-2">{checkLoading()}</div>
            <div className="w-24">
              <button
                type="button"
                onClick={() => handleClose()}
                className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-gray-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-gray-500 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              >
                Cancel
              </button>
            </div>
          </div>
          <div className="flex items-center">
            <div className="w-24">
              <button
                type="submit"
                disabled={isDisabled}
                className={`w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md ${
                  isDisabled
                    ? "opacity-50 cursor-default"
                    : "hover:bg-green-400"
                } text-white bg-green-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-green-600 transition duration-150 ease-in-out`}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </ModalParent>
  );
}
