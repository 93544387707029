import React from "react";
import { useRifm } from "rifm";
import { Link } from "@reach/router";
import { debounce } from "lodash";
import ReCAPTCHA from "react-google-recaptcha";
import useApiRequest from "../hooks/useApiRequest";
import igpLogo from "../assets/icons/igp_logo-title.png";
import { ModalBody, ModalButton, ModalParent } from "../elements/Modal";
import HelpModal from "../elements/HelpModal";
import Button from "../elements/Button";
import { useAuth0 } from "../contexts/auth0Context";

function TaxId({ value, handleChange, country = null }) {
  const [taxId, setTaxId] = React.useState("");

  const debounceValue = React.useCallback(
    debounce((e) => {
      handleChange(e);
    }, 1000),
    []
  );

  React.useEffect(() => (() => setTaxId(""))(), [country]);
  React.useEffect(() => {
    (() => {
      if (taxId) {
        if (value && taxId.includes("#")) {
          handleChange("");
        } else if (
          (country === "US" || country === "CA") &&
          !taxId.includes("#")
        ) {
          handleChange(taxId);
        } else if (country !== "US" && country !== "CA") {
          debounceValue(taxId);
        }
      }
    })();
  }, [taxId]);

  const parseDigits = (string) => (string.match(/\d+/g) || []).join("");
  const parseLettersAndDigits = (string) =>
    (string.match(/[a-zA-Z\d]+/g) || []).join("");
  const parseLetters = (string) => (string.match(/[a-zA-Z]+/g) || []).join("");
  const formatCA = (string) => {
    const lettersAndDigits = parseLettersAndDigits(string);
    const chars = lettersAndDigits.split("");
    return chars
      .reduce((acc, curr, index) => {
        return index === 9 || index === 11 ? `${acc}${curr}` : `${acc}${curr}`;
      }, "")
      .substr(0, 17);
  };
  const addCAMask = (string) => {
    const digits = parseDigits(string);
    const letters = parseLetters(string);
    const part1 = digits.slice(0, 9).padEnd(9, "#");
    const part2 = letters.slice(0, 2).padEnd(2, "R");
    const part3 = digits.slice(9, 13).padEnd(4, "#");

    return `${part1}${part2}${part3}`;
  };
  const formatUS = (string) => {
    const digits = parseDigits(string);
    const chars = digits.split("");
    return chars
      .reduce(
        (acc, curr, index) =>
          index === 1 ? `${acc}-${curr}` : `${acc}${curr}`,
        ""
      )
      .substr(0, 10);
  };
  const addUSMask = (string) => {
    const digits = parseDigits(string);
    const part1 = digits.slice(0, 2).padEnd(2, "#");
    const part2 = digits.slice(2, 9).padEnd(7, "#");
    return `${part1}-${part2}`;
  };

  const currentFormat = {
    format: country === "US" ? formatUS : country == "CA" ? formatCA : () => {},
    mask: country === "US" ? addUSMask : country == "CA" ? addCAMask : () => {},
  };
  const rifm = useRifm({
    accept: /[\w]/g,
    value: taxId,
    onChange: setTaxId,
    format: currentFormat.format,
    mask: true,
    replace: currentFormat.mask,
  });

  if (country !== "US" && country !== "CA") {
    return (
      <input
        id="taxid"
        name="taxid"
        type="text"
        required
        // pattern="[a-z]{3}"
        disabled={!country}
        className={`appearance-none ${
          country ? "" : "bg-gray-300"
        } block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
        value={taxId}
        onChange={(e) => {
          setTaxId(e.target.value);
        }}
      />
    );
  }

  return (
    <input
      id="taxid"
      name="taxid"
      type="text"
      pattern="[0-9a-zA-Z-]{1,}"
      required
      disabled={!country}
      className={`appearance-none ${
        country ? "" : "bg-gray-300"
      } block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
      value={rifm.value}
      onChange={(e) => {
        // e.persist();
        // if (e.target.value.includes("#")) {
        //   e.target.setCustomValidity("Please fill out this field");
        // } else {
        //   e.target.setCustomValidity("");
        // }
        rifm.onChange(e);
      }}
    />
  );
}

function Signup(props) {
  const webAuth = useAuth0();

  const [signupFields, setSignupFields] = React.useState({
    email: "",
    firstname: "",
    lastname: "",
    password: "",
    confirmpassword: "",
    orgname: "",
    orglocation: {},
    taxid: "",
  });
  const [errorMessage, setErrorMessage] = React.useState("");
  const [existingOrg, setExistingOrg] = React.useState({
    options: [],
    show: false,
  });
  const [taxOrg, setTaxOrg] = React.useState({
    options: [],
    show: false,
  });
  const [taxOrgSelection, setTaxOrgSelection] = React.useState(null);
  const [existingOrgSelection, setExistingOrgSelection] = React.useState(null);
  const [successSave, setSuccessSave] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const ref = React.useRef();
  const { fetchDataOnDemand } = useApiRequest({ isCallOnDemand: true });
  const { state: countryOptions } = useApiRequest({ reqType: "getLocation" });

  const reRef = React.useRef();

  React.useEffect(() => {
    const fetchExistingOrgs = async () => {
      let response =
        signupFields.taxid.length &&
        (await fetchDataOnDemand({
          reqType: "getExistingOrganizations",
          parameters: `?taxid=${signupFields.taxid}&countryid=${signupFields?.orglocation?.countryid}`,
        }));

      if (response.taxid) {
        setTaxOrg({
          options: [{ name: response.registeredname, taxid: response.taxid }],
          show: true,
        });
        setExistingOrgSelection("new");
      } else if (response.length) {
        setExistingOrg({ options: response, show: true });
      } else {
        setTaxOrg({ options: [], show: false });
        setExistingOrg({ options: [], show: false });
        if (signupFields.orgname) {
          setSignupFields((prev) => ({ ...prev, orgname: "" }));
          setExistingOrgSelection(null);
          setTaxOrgSelection(null);
        }
      }
    };
    if (signupFields.taxid) {
      fetchExistingOrgs();
    }
  }, [signupFields.taxid]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = reRef.current.getValue();
    setErrorMessage("");
    let body = {
      name: signupFields.orgname,
      ein: signupFields.taxid,
      user: {
        firstname: signupFields.firstname,
        lastname: signupFields.lastname,
        email: signupFields.email,
        password: signupFields.password,
      },
      createneworg: existingOrgSelection === "new" ? true : false,
      istaxrecord: !(taxOrgSelection === null) ? true : false,
      countryid: signupFields?.orglocation?.countryid,
      token,
    };
    setIsSubmitting(true);
    const response = await fetchDataOnDemand({
      reqType: "addUser",
      body,
    });

    reRef.current.reset();
    if (response) {
      webAuth.signup(
        {
          connection: "Username-Password-Authentication",
          email: signupFields.email,
          password: signupFields.password,
          user_metadata: {
            organizationuuid: response.organization.organizationuuid,
            useruuid: response.user.useruuid,
          },
        },
        function (err, res) {
          if (err) return alert("Something went wrong: " + err.message);
          webAuth.login({
            connection: "Username-Password-Authentication",
            email: signupFields.email,
            password: signupFields.password,
          });
        }
      );
      setSuccessSave(true);
    } else
      setErrorMessage(
        "User already exists. Please enter a different email address."
      );
    setIsSubmitting(false);

    //   if (!response.token) {
    //     setErrorMessage("Incorrect username or password");
    //     return;
    //   }
    //   setToken(response.token);
    //   if (props.redirect) {
    //     history.push(props.redirect);
    //   } else {
    //     history.push("/organization");
    //   }
  };

  const [isHelpOpen, setIsHelpOpen] = React.useState(false);
  if (successSave) {
    return (
      <div className="min-h-screen bg-purple-100 py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <h2 className=" text-center text-2xl leading-9 font-bold text-purple-800">
                Thank you for registering
              </h2>
              <p className="text-center mt-4 mx-4">
                Please check your inbox to confirm your email address before
                continuing.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="min-h-screen bg-purple-100 py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto w-40" src={igpLogo} alt="Workflow" />
        <h2 className="mt-6 text-center text-3xl leading-9 font-bold text-purple-800">
          Create your account
        </h2>
        <p className="text-center mt-4 mx-4">
          Please complete the information below to create an Impact Genome
          account.
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="my-6 relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <div className="relative flex justify-center text-sm leading-5">
                <span className="px-2 bg-white text-gray-500">
                  Personal Information
                </span>
              </div>
            </div>
            <div>
              <label
                htmlFor="text"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Email
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={signupFields.email}
                  onChange={(e) => {
                    e.persist();
                    setSignupFields((prev) => ({
                      ...prev,
                      email: e.target.value,
                    }));
                  }}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </div>

            <div className="mt-6">
              <label
                htmlFor="first name"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                First Name
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  id="firstname"
                  name="firstname"
                  type="text"
                  value={signupFields.firstname}
                  onChange={(e) => {
                    e.persist();
                    setSignupFields((prev) => ({
                      ...prev,
                      firstname: e.target.value,
                    }));
                  }}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </div>
            <div className="mt-6">
              <label
                htmlFor="last name"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Last Name
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  id="lastname"
                  name="lastname"
                  type="text"
                  value={signupFields.lastname}
                  onChange={(e) => {
                    e.persist();
                    setSignupFields((prev) => ({
                      ...prev,
                      lastname: e.target.value,
                    }));
                  }}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </div>
            <div className="mt-6">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Password
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={signupFields.password}
                  onChange={(e) => {
                    e.persist();
                    setSignupFields((prev) => ({
                      ...prev,
                      password: e.target.value,
                    }));
                  }}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </div>
            <div className="mt-6">
              <label
                htmlFor="confirm password"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Confirm Password
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  id="confirmpassword"
                  name="confirmpassword"
                  type="password"
                  ref={ref}
                  value={signupFields.confirmpassword}
                  onChange={(e) => {
                    e.persist();
                    if (e.target.value !== signupFields.password) {
                      e.target.setCustomValidity("Passwords do not match");
                    } else {
                      e.target.setCustomValidity("");
                    }
                    setSignupFields((prev) => ({
                      ...prev,
                      confirmpassword: e.target.value,
                    }));
                  }}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </div>
            <div className="my-6 relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <div className="relative flex justify-center text-sm leading-5">
                <span className="px-2 bg-white text-gray-500">
                  Organization Information
                </span>
              </div>
            </div>
            <div className="mt-6">
              <label
                htmlFor="Organization's Headquarters Location (Legal Domicile)"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Organization's Headquarters Location (Legal Domicile)
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <div>
                  <select
                    id="orglocation"
                    name="orglocation"
                    className="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                    required
                    // value={signupFields.orglocation}
                    placeholder="Select Country"
                    onChange={(e) => {
                      e.persist();
                      setSignupFields((prev) => ({
                        ...prev,
                        orglocation: JSON.parse(e.target.value),
                        taxid: "",
                        orgname: "",
                      }));
                      setTaxOrgSelection(null);
                      setExistingOrgSelection(null);
                    }}
                  >
                    <option value="" disabled selected>
                      Select a country
                    </option>
                    {countryOptions.data &&
                      countryOptions.data.map((country, index) => (
                        <React.Fragment key={index}>
                          <option value={JSON.stringify(country)}>
                            {country.name}
                          </option>
                        </React.Fragment>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="mt-6">
              <label
                htmlFor="Tax Id"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Tax Id
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <div>
                  <TaxId
                    value={signupFields.taxid}
                    handleChange={(e) => {
                      setSignupFields((prev) => ({
                        ...prev,
                        taxid: e,
                        orgname: "",
                      }));
                      setTaxOrgSelection(null);
                      setExistingOrgSelection(null);
                    }}
                    country={signupFields.orglocation?.code}
                  />
                </div>
              </div>
            </div>
            <div className="mt-6">
              {!taxOrgSelection && (
                <div className="flex flex-no-wrap justify-between">
                  <label
                    htmlFor="Organization Name"
                    className="block text-sm font-medium leading-5 text-gray-700"
                  >
                    Organization Name
                  </label>
                  <div
                    className="underline cursor-pointer"
                    onClick={() =>
                      setExistingOrg((prev) => ({ ...prev, show: true }))
                    }
                  >
                    {existingOrgSelection ? "Edit" : ""}
                  </div>
                </div>
              )}
              <div className="mt-1 rounded-md shadow-sm">
                <div>
                  <input
                    id="orgname"
                    name="orgname"
                    type="text"
                    value={signupFields.orgname}
                    onChange={(e) => {
                      e.persist();
                      setSignupFields((prev) => ({
                        ...prev,
                        orgname: e.target.value,
                      }));
                    }}
                    required
                    disabled={
                      !signupFields.taxid ||
                      (existingOrgSelection &&
                        existingOrgSelection !== "new") ||
                      taxOrgSelection
                    }
                    className={`appearance-none ${
                      !signupFields.taxid ||
                      (existingOrgSelection &&
                        existingOrgSelection !== "new") ||
                      taxOrgSelection
                        ? "bg-gray-300"
                        : ""
                    } block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                  />
                </div>
              </div>
            </div>
            <div className="mt-6 flex justify-center">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                ref={reRef}
              />
            </div>
            <div className="mt-6">
              <div className="">
                <div className="w-full">
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-400 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-green-600 transition duration-150 ease-in-out disabled:opacity-60"
                    disabled={isSubmitting}
                  >
                    Register
                  </button>
                  <div className="mt-2">
                    <a
                      className="font-medium text-xs underline text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                      href="https://impactgenome.org/privacy-and-legal/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms & Conditions
                    </a>
                  </div>
                </div>
              </div>
              <div className="mt-4 ">
                <div className="text-red-500 italic text-xs text-center">
                  {errorMessage}
                </div>
              </div>
            </div>

            <div className="mt-4">
              <span className="block w-full rounded-md shadow-sm"></span>
            </div>
          </form>

          <div className="mt-4">
            <hr />
            <div className="mt-6 ">
              <div className="text-purple-800 font-bold">
                Already have an account?
              </div>
              <div className="mt-3 flex flex-nowrap justify-between">
                <Link
                  to="/"
                  className="font-medium text-sm text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                >
                  Login Here
                </Link>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="flex-shrink-0 float-right -ml-1 mr-1 h-5 w-5 text-indigo-600 cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  onClick={() => setIsHelpOpen(true)}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <ModalParent
            isOpen={existingOrg.show}
            closeFunction={() => ""}
            maxWidth="sm:max-w-md"
          >
            <ModalBody
              title="An organization with this Tax ID has already been registered"
              noIcon
            >
              <div className="text-left sm:w-10/12 mx-auto mt-4 text-gray-800">
                <p className="mb-4">
                  Would you like to join this organization or create a new,
                  separate organization? Please select your organization from
                  the list below or create a new one.
                </p>
                <fieldset>
                  <legend className="sr-only">Organization Selection</legend>

                  {existingOrg.options.length &&
                    existingOrg.options.map((option, index) => (
                      <React.Fragment key={index}>
                        <div className="relative border border-gray-200 p-4 flex">
                          <div className="flex items-center h-5">
                            <input
                              id={`settings-option-${index}`}
                              name="privacy_setting"
                              type="radio"
                              checked={
                                existingOrgSelection?.name === option.name
                              }
                              className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out cursor-pointer"
                              onChange={() => {
                                setExistingOrgSelection(option);
                                setSignupFields((prev) => ({
                                  ...prev,
                                  orgname: option.name,
                                }));
                              }}
                            />
                          </div>
                          <label
                            htmlFor={`settings-option-${index}`}
                            className="ml-3 flex flex-col cursor-pointer"
                          >
                            {/* On: "text-indigo-900", Off: "text-gray-900" */}
                            <span className="block text-sm leading-5 ">
                              {option.name}
                            </span>
                            {/* On: "text-indigo-700", Off: "text-gray-500" */}
                          </label>
                        </div>
                      </React.Fragment>
                    ))}
                  {/* On: "bg-indigo-50 border-indigo-200 z-10", Off: "border-gray-200" */}

                  {/* On: "bg-indigo-50 border-indigo-200 z-10", Off: "border-gray-200" */}
                  <div className="relative border border-gray-200 rounded-bl-md rounded-br-md p-4 flex">
                    <div className="flex items-center h-5">
                      <input
                        id="settings-option-new"
                        name="privacy_setting"
                        type="radio"
                        className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out cursor-pointer"
                        checked={existingOrgSelection === "new"}
                        onChange={() => {
                          setExistingOrgSelection("new");
                          setSignupFields((prev) => ({
                            ...prev,
                            orgname: "",
                          }));
                        }}
                      />
                    </div>
                    <label
                      htmlFor="settings-option-new"
                      className="ml-3 flex flex-col cursor-pointer"
                    >
                      {/* On: "text-indigo-900", Off: "text-gray-900" */}
                      <span className="block text-sm leading-5">
                        Create a new sub organization
                      </span>
                      {/* On: "text-indigo-700", Off: "text-gray-500" */}
                    </label>
                  </div>
                </fieldset>
              </div>
            </ModalBody>
            <div className="flex flex-no-wrap justify-end mt-4">
              <Button
                className=""
                color={{
                  bg: "bg-green-600",
                  hoverbg: "hover:bg-green-500",
                  activebg: "active:bg-green-700",
                  focusborder: "focus:border-green-700",
                }}
                disabled={!existingOrgSelection}
                onClick={() => {
                  setExistingOrg((prev) => ({ ...prev, show: false }));
                }}
              >
                Save
              </Button>
            </div>
          </ModalParent>

          <ModalParent
            isOpen={taxOrg.show}
            closeFunction={() => ""}
            maxWidth="sm:max-w-md"
          >
            <ModalBody title="We have tax information!" noIcon>
              <div className="text-left sm:w-10/12 mx-auto mt-4 text-gray-800">
                <p className="mb-4">
                  We have tax records for the tax ID you entered. Please select
                  one of the following options:
                </p>
                <fieldset>
                  <legend className="sr-only">Organization Selection</legend>

                  {taxOrg.options.length &&
                    taxOrg.options.map((option, index) => (
                      <React.Fragment key={index}>
                        <div className="relative border border-gray-200 p-4 flex">
                          <div className="flex items-center h-5">
                            <input
                              id={`settings-option-${index}`}
                              name="privacy_setting"
                              type="radio"
                              checked={taxOrgSelection?.name === option.name}
                              className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out cursor-pointer"
                              onChange={() => {
                                setTaxOrgSelection(option);

                                setSignupFields((prev) => ({
                                  ...prev,
                                  orgname: option.name,
                                }));
                              }}
                            />
                          </div>
                          <label
                            htmlFor={`settings-option-${index}`}
                            className="ml-3 flex flex-col cursor-pointer"
                          >
                            {/* On: "text-indigo-900", Off: "text-gray-900" */}
                            <span className="block text-sm leading-5 ">
                              {option.name}
                            </span>
                            {/* On: "text-indigo-700", Off: "text-gray-500" */}
                          </label>
                        </div>
                      </React.Fragment>
                    ))}
                  <p className="mb-3 mt-2 text-sm text-gray-500">
                    If this organization is not your's, click cancel and check
                    that your taxid is correct.
                  </p>
                  {/* On: "bg-indigo-50 border-indigo-200 z-10", Off: "border-gray-200" */}
                </fieldset>
              </div>
            </ModalBody>
            <div className="flex flex-no-wrap justify-end mt-4">
              <Button
                className="mr-3"
                color={{
                  bg: "bg-green-600",
                  hoverbg: "hover:bg-green-500",
                  activebg: "active:bg-green-700",
                  focusborder: "focus:border-green-700",
                }}
                onClick={() => {
                  setTaxOrg((prev) => ({ ...prev, show: false }));
                  setTaxOrgSelection(null);
                  setExistingOrgSelection(null);
                  setSignupFields((prev) => ({
                    ...prev,
                    orgname: "",
                    taxid: "",
                  }));
                }}
              >
                Cancel
              </Button>
              <Button
                className=""
                color={{
                  bg: "bg-green-600",
                  hoverbg: "hover:bg-green-500",
                  activebg: "active:bg-green-700",
                  focusborder: "focus:border-green-700",
                }}
                disabled={!taxOrgSelection}
                onClick={() => {
                  setTaxOrg((prev) => ({ ...prev, show: false }));
                }}
              >
                Save
              </Button>
            </div>
          </ModalParent>
          {/* Help Button */}

          <HelpModal isHelpOpen={isHelpOpen} setIsHelpOpen={setIsHelpOpen} />
        </div>
      </div>
    </div>
  );
}

export default Signup;
