import React from "react";
import { render } from "react-dom";
import { Router } from "@reach/router";
import Login from "./pages/Login";
import Authorized from "./pages/Authorized";
import Signup from "./pages/Signup";
import "./App.css";

function App() {
  return (
    <Router>
      <Login path="/" />
      <Authorized path="authorized" />
      <Signup path="signup" />
    </Router>
  );
}

export default App;
