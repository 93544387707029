import React from "react";
import igpLogo from "../assets/icons/igp_logo-title.png";
import { useParams } from "@reach/router";

function Authorized() {
  let redirectUrl = localStorage.getItem("redirectUrl");
  let hash = new URL(document.location).hash;
  debugger;
  React.useEffect(() => {
    (() => {
      setTimeout(() => {
        window.location = `${redirectUrl}${hash}`;
      }, [1000]);
    })();
  }, []);
  return (
    <div className="min-h-screen bg-purple-100 py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto w-40" src={igpLogo} alt="Workflow" />
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <p className=" text-xl leading-9 font-bold text-purple-900">
            Thank you for logging in! You will now be returned to you
            application.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Authorized;
