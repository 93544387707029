import axios from "axios";
const url = process.env.REACT_APP_API_URL;

const taxonomyUrl = `https://api.impactgenome.com/taxonomy`;
const commonUrl = `${url}/common`;
// const commonUrl = "http://localhost:3003/common";
const hubUrlPrograms = `${url}/hub/v1`;
// const hubUrlPrograms = `http://localhost:3009/hub/v1`;
const scorecardUrl = `${url}/scorecard`;
const surveyUrl = `${url}/survey`;
// const surveyUrl = `http://localhost:3001/survey`;
const programEditorUrl = `${url}/program-editor`;
// const programEditorUrl = `http://localhost:3007/program-editor`;

const awsUrl = `${url}/aws`;
// const awsUrl = "http://localhost:3008/aws";

// const surveyUrl = "https://api-dev.impactgenome.com/survey";
// const surveyDraft = `${url}/surveysurveyid=2`;
const config = {
  headers: { Authorization: `Bearer ${process.env.REACT_APP_BEARER}` },
};
const api = {
  login: ({ parameters = "", body = "" } = {}) => {
    return axios.post(`${commonUrl}/users/login`, body, config);
  },
  getImpactAreas: ({ parameters = "", body = "" } = {}) => {
    return axios.get(`${taxonomyUrl}/impactareas${parameters}`, config);
  },

  getGenomes: ({ parameters = "", body = "" } = {}) => {
    return axios.get(`${taxonomyUrl}/genomes${parameters}`, config);
  },

  getOutcomes: ({ parameters = "", body = "" } = {}) => {
    return axios.get(`${taxonomyUrl}/outcomes${parameters}`, config);
  },
  getOutcomesFull: ({ parameters = "", body = "" } = {}) => {
    return axios.get(`${taxonomyUrl}/outcomes/full${parameters}`, config);
  },

  getGenes: ({ parameters = "", body = "" } = {}) => {
    return axios.get(`${taxonomyUrl}/genes${parameters}`, config);
  },
  getActivities: ({ parameters = "", body = "" } = {}) => {
    return axios.get(`${taxonomyUrl}/activities${parameters}`, config);
  },

  postContactInfo: ({ parameters = "", body = "" } = {}) => {
    return axios.post(`${taxonomyUrl}/contact`, body, config);
  },

  getSurvey: ({ parameters = "", body = "" } = {}) => {
    return axios.get(`${surveyUrl}${parameters}`, config);
  },

  postSurvey: ({ parameters = "", body = "" } = {}) => {
    return axios.post(`${surveyUrl}${parameters}`, body, config);
  },
  postResetPassword: ({ parameters = "", body = "" } = {}) => {
    return axios.post(`${commonUrl}/users/resetpassword`, body, config);
  },
  getResetPasswordRequest: ({ parameters = "", body = "" } = {}) => {
    return axios.get(
      `${commonUrl}/users/passwordresetrequest?${parameters}`,
      config
    );
  },
  sendPasswordResetRequest: ({ parameters = "", body = "" } = {}) => {
    return axios.post(`${commonUrl}/users/passwordresetrequest`, body, config);
  },
  postCloneSurvey: ({ parameters = "", body = "" } = {}) => {
    return axios.post(`${surveyUrl}/clone`, body, config);
  },

  getLocation: ({ parameters = "", body = "" } = {}) => {
    return axios.get(`${commonUrl}/countries`, config);
  },
  getBeneTypes: ({ parameters = "", body = "" } = {}) => {
    return axios.get(`${taxonomyUrl}/beneficiarytypes`, config);
  },
  getClassifications: ({ parameters = "", body = "" } = {}) => {
    return axios.get(`${taxonomyUrl}/programclassifications`, config);
  },
  getOutcomeBenchmarks: ({ parameters = "", body = "" } = {}) => {
    return axios.get(`${taxonomyUrl}/benchmarkdata`, config);
  },
  getOrganizations: ({ parameters = "", body = "" } = {}) => {
    return axios.get(`${commonUrl}/organizations${parameters}`, config);
  },
  getOrganizationMetadata: ({ parameters = "", body = "" } = {}) => {
    return axios.get(
      `${commonUrl}/organizations/metadata${parameters}`,
      config
    );
  },
  updateOrganizationMetadata: ({ parameters = "", body = "" } = {}) => {
    return axios.post(
      `${commonUrl}/organizations/metadata${parameters}`,
      body,
      config
    );
  },
  getPrograms: ({ parameters = "", body = "" } = {}) => {
    return axios.get(`${hubUrlPrograms}/program${parameters}`, config);
  },
  addProgramReport: ({ parameters = "", body = "" } = {}) => {
    return axios.post(`${hubUrlPrograms}/program/reports`, body, config);
  },
  deleteProgramReport: ({ parameters = "", body = "" } = {}) => {
    return axios.delete(
      `${hubUrlPrograms}/program/reports${parameters}`,
      config
    );
  },
  addProgram: ({ parameters = "", body = "" } = {}) => {
    return axios.post(`${hubUrlPrograms}/program`, body, config);
  },
  updateProgram: ({ parameters = "", body = "" } = {}) => {
    return axios.put(`${hubUrlPrograms}/program`, body, config);
  },
  deleteProgram: ({ parameters = "", body = "" } = {}) => {
    return axios.delete(`${hubUrlPrograms}/program${parameters}`, config);
  },
  confirmRegistration: ({ parameters = "", body = "" } = {}) => {
    return axios.post(
      `${commonUrl}/registration/confirm${parameters}`,
      body,
      config
    );
  },
  getUser: ({ parameters = "", body = "" } = {}) => {
    return axios.get(`${commonUrl}/users/info${parameters}`, config);
  },
  addUser: ({ parameters = "", body = "" } = {}) => {
    return axios.post(`${commonUrl}/organizations/simple`, body, config);
  },
  getOrganizationUsers: ({ parameters = "", body = "" } = {}) => {
    return axios.get(`${commonUrl}/organizations/users${parameters}`, config);
  },
  getOrganizationRequests: ({ parameters = "", body = "" } = {}) => {
    return axios.get(
      `${commonUrl}/organizations/requests${parameters}`,
      config
    );
  },
  approveOrganizationRequest: ({ parameters = "", body = "" } = {}) => {
    return axios.post(
      `${commonUrl}/organizations/requests/approve${parameters}`,
      body,
      config
    );
  },
  getExistingOrganizations: ({ parameters = "", body = "" } = {}) => {
    return axios.get(`${commonUrl}/organizations/exists${parameters}`, config);
  },
  getRegions: ({ parameters = "", body = "" } = {}) => {
    return axios.get(`${commonUrl}/regions${parameters}`, config);
  },
  getCountries: ({ parameters = "", body = "" } = {}) => {
    return axios.get(`${commonUrl}/countries`, config);
  },
  getSia: ({ parameters = "", body = "" } = {}) => {
    return axios.get(`${programEditorUrl}/sia${parameters}`, config);
  },
  getAssignedSia: ({ parameters = "", body = "" } = {}) => {
    return axios.get(`${programEditorUrl}/assigned${parameters}`, config);
  },
  addAssignedSia: ({ parameters = "", body = "" } = {}) => {
    return axios.get(`${programEditorUrl}/assigned${parameters}`, config);
  },
  updateAssignedSia: ({ parameters = "", body = "" } = {}) => {
    return axios.put(`${programEditorUrl}/assigned${parameters}`, body, config);
  },
  getComments: ({ parameters = "", body = "" } = {}) => {
    return axios.get(
      `${programEditorUrl}/programreportdata/comments${parameters}`,
      config
    );
  },
  postComments: ({ parameters = "", body = "" } = {}) => {
    return axios.post(
      `${programEditorUrl}/programreportdata/comments${parameters}`,
      body,
      config
    );
  },
  getFiles: ({ parameters = "", body = "" } = {}) => {
    return axios.get(
      `${programEditorUrl}/programreportdata/files${parameters}`,
      config
    );
  },
  deleteFile: ({ parameters = "", body = "" } = {}) => {
    return axios.delete(
      `${programEditorUrl}/programreportdata/files${parameters}`,
      config
    );
  },
  getProgramReports: ({ parameters = "", body = "" } = {}) => {
    return axios.get(`${programEditorUrl}/programreports${parameters}`, config);
  },
  getProgramReportData: ({ parameters = "", body = "" } = {}) => {
    return axios.get(
      `${programEditorUrl}/programreportdata${parameters}`,
      config
    );
  },
  postProgramReportData: ({ parameters = "", body = "" } = {}) => {
    return axios.post(
      `${programEditorUrl}/programreportdata${parameters}`,
      body,
      config
    );
  },
  getProgramReportQuestions: ({ parameters = "", body = "" } = {}) => {
    return axios.get(
      `${programEditorUrl}/programreportquestions${parameters}`,
      config
    );
  },
  getProgramReportScorecard: ({ parameters = "", body = "" } = {}) => {
    return axios.get(`${scorecardUrl}${parameters}`, {
      ...config,
      responseType: "blob",
    });
  },
  uploadProgramReportFile: ({ parameters = "", body = "" } = {}) => {
    let form = new FormData();
    form.append("file", body, body.name);
    return axios.post(
      `${awsUrl}/programreports/programfile${parameters}`,
      form,
      {
        ...config,
        headers: {
          ...config.headers,
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  uploadLogoFile: ({ parameters = "", body = "" } = {}) => {
    let form = new FormData();
    form.append("logo", body, `logo`);
    return axios.post(`${awsUrl}/organizations/logo${parameters}`, form, {
      ...config,
      headers: {
        ...config.headers,
        "Content-Type": "multipart/form-data",
      },
    });
  },
  //Funder Terminal
  addFunder: ({ parameters = "", body = "" } = {}) => {
    return axios.post(
      `${commonUrl}/organizations/funder${parameters}`,
      body,
      config
    );
  },
  updateFunder: ({ parameters = "", body = "" } = {}) => {
    return axios.put(
      `${commonUrl}/organizations/funder${parameters}`,
      body,
      config
    );
  },
  getFunder: ({ parameters = "", body = "" } = {}) => {
    return axios.get(`${commonUrl}/organizations/funder${parameters}`, config);
  },
  addPortfolio: ({ parameters = "", body = "" } = {}) => {
    return axios.post(
      `${commonUrl}/portfolios/portfolio${parameters}`,
      body,
      config
    );
  },
  updatePortfolio: ({ parameters = "", body = "" } = {}) => {
    return axios.put(
      `${commonUrl}/portfolios/portfolio${parameters}`,
      body,
      config
    );
  },
  getPortfolio: ({ parameters = "", body = "" } = {}) => {
    return axios.get(`${commonUrl}/portfolios/portfolio${parameters}`, config);
  },
  addPortfolioGroup: ({ parameters = "", body = "" } = {}) => {
    return axios.post(
      `${commonUrl}/portfolios/portfoliogroup${parameters}`,
      body,
      config
    );
  },
  updatePortfolioGroup: ({ parameters = "", body = "" } = {}) => {
    return axios.put(
      `${commonUrl}/portfolios/portfoliogroup${parameters}`,
      body,
      config
    );
  },

  getPortfolioGroup: ({ parameters = "", body = "" } = {}) => {
    return axios.get(
      `${commonUrl}/portfolios/portfoliogroup${parameters}`,
      config
    );
  },
  addPortfolioGroupPortfolio: ({ parameters = "", body = "" } = {}) => {
    return axios.post(
      `${commonUrl}/portfolios/portfoliogroupportfolio${parameters}`,
      body,
      config
    );
  },

  getPortfolioGroupPortfolio: ({ parameters = "", body = "" } = {}) => {
    return axios.get(
      `${commonUrl}/portfolios/portfoliogroupportfolio${parameters}`,
      config
    );
  },
  addPortfolioPrograms: ({ parameters = "", body = "" } = {}) => {
    return axios.post(
      `${commonUrl}/portfolios/portfolioprogram${parameters}`,
      body,
      config
    );
  },

  getPortfolioPrograms: ({ parameters = "", body = "" } = {}) => {
    return axios.get(
      `${commonUrl}/portfolios/portfolioprogram${parameters}`,
      config
    );
  },

  getOrganizationUsers: ({ parameters = "", body = "" } = {}) => {
    return axios.get(
      `${commonUrl}/users/organizationusers${parameters}`,
      config
    );
  },
  addPrimaryContact: ({ parameters = "", body = "" } = {}) => {
    return axios.post(
      `${programEditorUrl}/programreportdata/primarycontact${parameters}`,
      body,
      config
    );
  },
  addHelpRequest: ({ parameters = "", body = "" } = {}) => {
    return axios.post(
      `${commonUrl}/helprequests/create${parameters}`,
      body,
      config
    );
  },
  getDei: ({ parameters = "", body = "" } = {}) => {
    return axios.get(`${commonUrl}/organizations/dei${parameters}`, config);
  },
  addDei: ({ parameters = "", body = "" } = {}) => {
    return axios.post(
      `${commonUrl}/organizations/dei${parameters}`,
      body,
      config
    );
  },
  getDeiDates: ({ parameters = "", body = "" } = {}) => {
    return axios.get(
      `${commonUrl}/organizations/dei/dates${parameters}`,
      config
    );
  },
  updateReportingDates: ({ parameters = "", body = "" } = {}) => {
    return axios.put(
      `${commonUrl}/programreports/updatereportingperiod${parameters}`,
      body,
      config
    );
  },
  //test api
  getTestPortfolios: ({ parameters = "", body = "" } = {}) => {
    return axios.get(
      `https://my.api.mockaroo.com/portfolio_list.jsonkey=9a866ae0`
    );
  },
  getTestFunders: ({ parameters = "", body = "" } = {}) => {
    return axios.get(
      `https://my.api.mockaroo.com/funder_list.jsonkey=9a866ae0`
    );
  },
  addHelpRequest: ({ parameters = "", body = "" } = {}) => {
    return axios.post(
      `${commonUrl}/helprequests/create${parameters}`,
      body,
      config
    );
  },
};

export default api;
