import React from "react";

const Auth0StateContext = React.createContext();

function Auth0Provider({ children, data }) {
  return (
    <Auth0StateContext.Provider value={data}>
      {children}
    </Auth0StateContext.Provider>
  );
}

function useAuth0() {
  const context = React.useContext(Auth0StateContext);
  if (context === undefined) {
    throw new Error("useGlobalVarState must be used within GlobalVarProvider");
  }
  return context;
}

export { Auth0Provider, useAuth0 };
