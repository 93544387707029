import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import auth0 from "auth0-js";
import { Auth0Provider } from "./contexts/auth0Context";

var auth0WebAuth = new auth0.WebAuth({
  clientID: "HELZY4v1gpBpYgByWrOBhZtuA2U0q1Vt",
  domain: "impactgenome.us.auth0.com",
  redirectUri: "https://login.impactgenome.com/authorized",
  responseType: "token id_token",
  audience: "https://resolve/api",
  scope: "openid profile email read:programs",
});
ReactDOM.render(
  <Auth0Provider data={auth0WebAuth}>
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
