import React from "react";
import igpLogo from "../assets/icons/igp_logo-title.png";
import { Link } from "@reach/router";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import ReactCodeInput from "react-verification-code-input";
import HelpModal from "../elements/HelpModal";
import { useAuth0 } from "../contexts/auth0Context";

function Login() {
  let params = new URL(document.location).searchParams;
  const redirectUrl = params.get("redirecturi");
  localStorage.removeItem("redirectUrl");
  localStorage.setItem("redirectUrl", redirectUrl);
  const [value, setValue] = React.useState({
    email: "",
  });
  const webAuth = useAuth0();
  const [isEmailSent, setIsEmailSet] = React.useState(false);
  const [code, setCode] = React.useState();
  const [isValid, setIsValid] = React.useState(true);
  const [isHelpOpen, setIsHelpOpen] = React.useState(false);

  const handleChange = (e) => {
    setValue((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSendCode = async () => {
    try {
      const regex = new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (value?.email && regex.test(value.email)) {
        webAuth.passwordlessStart(
          {
            connection: "email",
            send: "code",
            email: value.email,
          },
          function (err, res) {
            // handle errors or continue
            setIsEmailSet(true);
          }
        );
      } else {
        setIsValid(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleVerifyCode = async () => {
    try {
      webAuth.passwordlessLogin(
        {
          connection: "email",
          email: value.email,
          verificationCode: code,
        },
        function (err, res) {
          //   console.log(res);
        }
      );
      //   window.location = "https://google.com";
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="min-h-screen bg-purple-100 py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto w-40" src={igpLogo} alt="Workflow" />
        <h2 className="mt-6 text-center text-3xl leading-9 font-bold text-purple-800">
          Sign in to your account
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 relative">
          {!isEmailSent ? (
            <>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    className="block w-full pr-10 focus:outline-none  sm:text-sm rounded-md"
                    aria-invalid="true"
                    aria-describedby="email-error"
                    value={value.email}
                    onChange={(e) => handleChange(e)}
                    onFocus={() => !isValid && setIsValid(true)}
                  />

                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon
                      className={`${isValid && "hidden"} h-5 w-5 text-red-500`}
                      aria-hidden="false"
                    />
                  </div>
                </div>

                <p
                  className={`${isValid && "hidden"} mt-2 text-sm text-red-600`}
                  id="email-error"
                >
                  Please provide a valid email address.
                </p>
              </div>
              <div className="flex flex-nowrap items-center justify-between">
                <button
                  type="button"
                  className=" flex-none my-4 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={handleSendCode}
                >
                  Send Code
                </button>
                <div className="text-xs w-5/12">
                  Don't have an account? Click{" "}
                  <Link to="signup" className="text-blue-500 underline">
                    here
                  </Link>{" "}
                  to signup.
                </div>
              </div>
              <hr />
              <p className="text-sm mt-2">
                Please enter the email associated with your Impact Genome
                account. If the email exists in our records, you will be sent a
                verification code to login with.
              </p>
            </>
          ) : (
            <>
              <ReactCodeInput onComplete={(e) => setCode(e)} />
              <div className="flex flex-nowrap items-center justify-between">
                <button
                  type="button"
                  className="flex-none my-4 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={handleVerifyCode}
                >
                  Verify
                </button>
                <div className="text-xs w-5/12">
                  Don't have an account? Click{" "}
                  <Link to="signup" className="text-blue-500 underline">
                    here
                  </Link>{" "}
                  to signup.
                </div>
              </div>
              <hr />
              <p className="text-sm mt-2">
                If you did not receive an email, please click{" "}
                <button
                  className="text-blue-500 underline"
                  onClick={() => setIsEmailSet(false)}
                >
                  here
                </button>{" "}
                to try a different one or click the help icon to submit a help
                request.
              </p>
            </>
          )}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="absolute bottom-2 right-2  -ml-1 mr-1 h-5 w-5 text-indigo-600 cursor-pointer"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            onClick={() => setIsHelpOpen(true)}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
      </div>
      <HelpModal isHelpOpen={isHelpOpen} setIsHelpOpen={setIsHelpOpen} />
    </div>
  );
}

export default Login;
