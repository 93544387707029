import React from "react";

function Button(props) {
  const {
    variant = "secondary",
    onClick = () => {},
    size = "md",
    children,
    className = "",
    colors = {
      bg: "bg-gray-600",
      hoverbg: "hover:bg-gray-500",
      activebg: "active:bg-gray-700",
      focusborder: "focus:border-gray-700",
    },
    disabled,
  } = props;

  return (
    <div className={className}>
      <span className="inline-flex rounded-md shadow-sm w-full">
        <button
          type="button"
          className={` w-full mx-auto inline-flex items-center px-6 py-2 border border-transparent text-base justify-center leading-6 font-medium rounded text-white ${
            colors.bg
          }  focus:outline-none ${
            colors.focusborder
          }focus:shadow-outline-gray ${colors.activebg} ${
            disabled && "cursor-not-allowed"
          } transition ease-in-out duration-150 disabled:opacity-30`}
          onClick={onClick}
          disabled={disabled}
        >
          {children}
        </button>
      </span>
    </div>
  );
}
//${colors[variant]}
export default Button;
